import React, { useEffect } from "react";
import './Review.css';
import '../../App.css';
import '../../lib/owl.carousel.css'
import AOS from "aos";

export default function Review() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
    return (
        <div class="review">
            <div class="review__center center">
                <h2 class="review__title h2" data-aos="animation-scale-top" data-aos-duration="600">What people are saying</h2>
                <div class="review__container">
                <div class="review__slider owl-carousel js-slider-review">

                <div class="review__item">
                    <div class="review__text">“ Solo makes life easier to me. With Solo you can organize your work and life in seconds. <br/>5 stars!!! ”</div>
                    <div class="review__rating">
                     <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                     <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    </div>
                    <div class="review__author">Jaida Barton</div>
                    <div class="review__company">Visual Designer at UI8</div>
                </div>

                <div class="review__item">
                    <div class="review__text">“ Solo makes life easier to me. With Solo you can organize your work and life in seconds. <br/>5 stars!!! ”</div>
                    <div class="review__rating">
                     <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                     <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    </div>
                    <div class="review__author">Jaida Barton</div>
                    <div class="review__company">Visual Designer at UI8</div>
                </div>

                <div class="review__item">
                    <div class="review__text">“ Solo makes life easier to me. With Solo you can organize your work and life in seconds. <br/>5 stars!!! ”</div>
                    <div class="review__rating">
                     <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                     <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    <div class="review__icon"><img src="img/star-fill.svg" alt=""/></div>
                    </div>
                    <div class="review__author">Jaida Barton</div>
                    <div class="review__company">Visual Designer at UI8</div>
                </div>

                </div>
                </div>
            </div>
        </div>
    );
}
import React, { useEffect } from "react";
import './Advantages.css';
import '../../App.css';
import AOS from "aos";

export default function Advantages() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
  
    return (<div class="advantages">
    <div class="advantages__center center">
      <div class="advantages__head">
        <div class="stage">anyone and everyone</div>
        <h2 class="advantages__title h2" data-aos="animation-scale-top" data-aos-duration="600">For eCommerce, Recruitment, Marketing, Education & more</h2>
      </div>
      <div class="advantages__list">
        <div class="advantages__item">
          <div class="advantages__bg">
            <div class="advantages__preview"><img srcset="img/advantages-pic-1@2x.png" src="img/advantages-pic-1.png" alt=""/></div>
            <div class="advantages__preview" data-aos="animation-scale-top" data-aos-duration="800"><img srcset="img/cube@2x.png 2x" src="img/cube.png" alt=""/></div>
            <div class="advantages__preview" data-aos="animation-scale-top" data-aos-duration="800" data-aos-delay="400"><img srcset="img/ball@2x.png 2x" src="img/ball.png" alt=""/></div>
          </div>
          <div class="advantages__wrap">
            <div class="advantages__icon" align="left" data-aos="animation-scale-top" data-aos-duration="800"><img srcset="img/Ecommerce icon" src="img/Ecommerce icon.svg" alt=""/></div>
            <div class="advantages__info" data-aos="animation-scale-top" data-aos-duration="800" data-aos-delay="400" align="left">Grow your eCommerce business</div>
            <div class="advantages__text" align="left">Create forms for market research, order form, product feedback, and video testimonials.</div>
            <button class="advantages__btn btn btn_border " >Use Template</button>
          </div>
        </div>
        <div class="advantages__item">
          <div class="advantages__bg">
            <div class="advantages__preview"><img srcset="img/advantages-pic-2@2x.png" src="img/advantages-pic-2.png" alt=""/></div>
            <div class="advantages__preview" data-aos="animation-scale-top" data-aos-duration="800"><img srcset="img/triangle@2x.png 2x" src="img/triangle.png" alt=""/></div>
            <div class="advantages__preview" data-aos="animation-scale-top" data-aos-duration="800" data-aos-delay="400"><img srcset="img/cube@2x.png 2x" src="img/cube.png" alt=""/></div>
          </div>
          <div class="advantages__wrap">
            <div class="advantages__icon" align="left" data-aos="animation-scale-top" data-aos-duration="800"><img srcset="img/Contactformicon.svg" src="img/Contactformicon.svg" alt=""/></div>
            <div class="advantages__info" align="left" data-aos="animation-scale-top" data-aos-duration="800" data-aos-delay="400">Create a quick website with a contact form </div>
            <div class="advantages__text" align="left">Add logo, links, header with markdown support. Show off your work, blog, code, add images and videos.</div>
            <button class="advantages__btn btn btn_border">Get Your Website</button>
          </div>
        </div>
        <div class="advantages__item">
          <div class="advantages__bg">
            <div class="advantages__preview"><img srcset="img/advantages-pic-3@2x.png" src="img/advantages-pic-3.png" alt=""/></div>
            <div class="advantages__preview" data-aos="animation-scale-top" data-aos-duration="800"><img srcset="img/cube@2x.png 2x" src="img/cube.png" alt=""/></div>
            <div class="advantages__preview" data-aos="animation-scale-top" data-aos-duration="800" data-aos-delay="400"><img srcset="img/ball@2x.png 2x" src="img/ball.png" alt=""/></div>
          </div>
          <div class="advantages__wrap">
            <div class="advantages__icon" align="left" data-aos="animation-scale-top" data-aos-duration="800"><img srcset="img/libraryicon.svg" src="img/libraryicon.svg" alt=""/></div>
            <div class="advantages__info" align="left" data-aos="animation-scale-top" data-aos-duration="800" data-aos-delay="400">API & UI library for business</div>
            <div class="advantages__text"  align="left">Use form on your SAAS product, website, ATS system, mobile apps with our form API & UI library.</div>
            <button class="advantages__btn btn btn_border">Check API Documentation</button>
          </div>
        </div>
      </div>
    </div>
  </div>);
}
import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './App.css'
import {Routes,Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom'

function RoutesTracker() {
    return (
      <><><div>
        <Header />
        {/* <Home /> */}
      </div><div>
          <Routes>
            <Route exact path="/About" element={<About />} />
          </Routes>
          <Routes>
            <Route exact path="/Contact" element={<Contact />} />
          </Routes>
          <Routes>
            <Route exact path="/" element={<Home />} />
          </Routes>
        </div></><div>
          <Footer />
        </div></>
    );
  }
  
export default RoutesTracker;
  
import React, { useEffect } from "react";
import './Footer.css';
import '../../App.css';
import AOS from "aos";

export default function Footer() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
    return ( 
      <div class="footer">
      <div class="footer__center center">
        <div class="footer__body">
          <div class="footer__wrap"><a class="footer__logo" href="index.html"><img class="footer__pic footer__pic_dark" src="img/logo-dark.png" alt="" /><img class="footer__pic footer__pic_light" src="img/logo-light.png" alt="" /></a>
            <div class="footer__text">Join millions of people who organize work and life with Solo App.</div>
          </div>
          <div class="footer__line">
            <div class="footer__nav"><a class="footer__link" href="about-us.html">About</a><a class="footer__link" href="contact.html">Contact</a></div>
          </div>
        </div>
        <div class="footer__bottom">
          <div class="footer__copyright">© Solo Inc. Designed by UI8 Team</div>
          <div class="footer__social"><a class="footer__link" href="#"><svg class="icon icon-facebook">
                <use xlinkhref="img/sprite.svg#icon-facebook"></use>
              </svg></a><a class="footer__link" href="#"><svg class="icon icon-twitter">
                <use xlinkhref="img/sprite.svg#icon-twitter"></use>
              </svg></a><a class="footer__link" href="#"><svg class="icon icon-instagram">
                <use xlinkhref="img/sprite.svg#icon-instagram"></use>
              </svg></a></div>
        </div>
      </div><label class="switch js-switch-theme"><input class="switch__input" type="checkbox" /><span class="switch__in"><span class="switch__box"></span><span class="switch__icon switch__icon_dark"><svg class="icon icon-dark">
              <use xlinkhref="img/sprite.svg#icon-dark"></use>
            </svg></span><span class="switch__icon switch__icon_light"><svg class="icon icon-light">
              <use xlinkhref="img/sprite.svg#icon-light"></use>
            </svg></span></span></label>
    </div>);
}
import React, { useEffect } from "react";
import './Options.css';
import '../../App.css';
import AOS from "aos";

export default function Options() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
    return (
    <div class="options">
    <div class="options__center center">
      <div class="options__wrap">
        <div class="options__list">
          <div class="options__item">
            <div class="options__icon" align="left"><img srcset="img/component.svg" src="img/component.svg" alt=""/></div>
            <div class="options__info" align="left">UI componentx<br/>library</div>
            <div class="options__text" align="left">17 curated components supporting long text, ratings, links, selection, and more</div>
          </div>
          <div class="options__item">
            <div class="options__icon"align="left"><img srcset="img/mail.svg" src="img/mail.svg" alt=""/></div>
            <div class="options__info"align="left">Get responses <br/>in mail</div>
            <div class="options__text"align="left">4,729 curated designresources to energize your creative workflow</div>
          </div>
          <div class="options__item">
            <div class="options__icon"align="left"><img srcset="img/user.svg" src="img/user.svg" alt=""/></div>
            <div class="options__info"align="left">Invite a user to fill the forms</div>
            <div class="options__text"align="left">4,729 curated designresources to energize your creative workflow</div>
          </div>
          <div class="options__item">
            <div class="options__icon"align="left"><img srcset="img/csv.svg" src="img/csv.svg" alt=""/></div>
            <div class="options__info"align="left">Download response data  in CSV</div>
            <div class="options__text"align="left">4,729 curated designresources to energize your creative workflow</div>
          </div>
          <div class="options__item">
            <div class="options__icon"align="left"><img srcset="img/upload.svg" src="img/upload.svg" alt=""/></div>
            <div class="options__info"align="left">File upload support</div>
            <div class="options__text"align="left">4,729 curated designresources to energize your creative workflow</div>
          </div>
          <div class="options__item" align="left">
            <div class="options__icon"><img srcset="img/response.svg" src="img/response.svg" alt=""/></div>
            <div class="options__info">Collect anonymous response</div>
            <div class="options__text">4,729 curated designresources to energize your creative workflow</div>
          </div>
          <div class="options__item" align="left">
            <div class="options__icon"><img srcset="img/form.svg" src="img/form.svg" alt=""/></div>
            <div class="options__info">Embed the form on website</div>
            <div class="options__text">4,729 curated designresources to energize your creative workflow</div>
          </div>
          <div class="options__item" align="left">
            <div class="options__icon"><img srcset="img/drive.svg" src="img/drive.svg" alt=""/></div>
            <div class="options__info">All files in one place with drive support</div>
            <div class="options__text">4,729 curated designresources to energize your creative workflow</div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}
import React, { useEffect, useState } from "react";
import './Main.css';
import '../../App.css';
import AOS from "aos";
import '../../../node_modules/react-modal-video/css/modal-video.css';
import ModalVideo from 'react-modal-video'

export default function Main() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
	const [isOpen, setOpen] = useState(false)

    return (
        <div class="main">
        <div class="main__center center">
          <div class="main__wrap">
            <div align="left" class="stage">FREE PLATFORM WITH ALL FEATURES</div>
            <h1 align="left"class="main__title d1"><span data-aos="animation-scale-top" data-aos-duration="600">No-Code platform </span><span data-aos="animation-scale-top" data-aos-duration="600" data-aos-delay="200">for your business</span><span data-aos="animation-scale-top" data-aos-duration="600" data-aos-delay="400"> </span></h1>
            <div class="main__text">Create feedback, survey, and web page with CT Forms</div>
            <div class="main__btns">
            <a class="header__btn btn btn_orange" href="https://app.forms.cyphertree.com/register" data-effect="mfp-zoom-in">Register</a>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="pJkqPLcTYzI" onClose={() => setOpen(false)} />
            <button class="main__btn btn btn_border" onClick={()=> setOpen(true)}>Watch how it works</button>

            </div>
          </div>
          <div class="main__bg" data-aos>
            <div class="main__preview"><img srcset="img/figures-1.1@2x.png 2x" src="img/figures-1.1.png" alt="" /></div>
            {/* <div class="main__preview"><img srcset="img/figures-1.2@2x.png 2x" src="img/figures-1.2.png" alt=""/></div> */}
            {/* <div class="main__preview"><img srcset="img/figures-1.3@2x.png 2x" src="img/figures-1.3.png" alt=""/></div> */}
            {/* <div class="main__preview"><img srcset="img/figures-1.4@2x.png 2x" src="img/figures-1.4.png" alt=""/></div> */}
            {/* <div class="main__preview"><img srcset="img/figures-1.5@2x.png 2x" src="img/figures-1.5.png" alt=""/></div> */}
            {/* <div class="main__preview"><img srcset="img/figures-1.6@2x.png 2x" src="img/figures-1.6.png" alt=""/></div> */}
          </div>
        </div>
      </div>
    );
}
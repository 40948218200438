import React, { useEffect } from "react";
import './Steps.css';
import '../../App.css';
import AOS from "aos";

export default function Steps() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
  
    return (
<div class="steps">
        <div class="steps__center center">
          <div class="stage">INSTANT SETUP</div>
          <h2 class="steps__title h2" data-aos="animation-scale-top" data-aos-duration="600">Fast, simple & effortless.</h2>
          <div class="steps__list">
            <div class="steps__item">
              <div class="steps__icon"><img srcset="img/register.svg" src="img/register.svg" alt=""/></div>
              <div class="steps__number">Step 1</div>
              <div class="steps__text">Register</div>
            </div>
            <div class="steps__item">
              <div class="steps__icon"><img srcset="img/create.svg" src="img/create.svg" alt=""/></div>
              <div class="steps__number">Step 2</div>
              <div class="steps__text">Create</div>
            </div>
            <div class="steps__item">
              <div class="steps__icon"><img srcset="img/share.svg" src="img/share.svg" alt=""/></div>
              <div class="steps__number">Step 3</div>
              <div class="steps__text">Share</div>
            </div>
            <div class="steps__item">
              <div class="steps__icon"><img srcset="img/track.svg" src="img/track.svg" alt=""/></div>
              <div class="steps__number">Step 4</div>
              <div class="steps__text">Track</div>
            </div>
          </div>
        </div>
      </div>
    );
}
import React, { useEffect } from "react";
import './Invite.css';
import '../../App.css';
import AOS from "aos";

export default function Invite() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
    return (<div class="invite">
    <div class="invite__center center">
      <div class="invite__container">
        <div class="invite__bg"></div>
        <div class="invite__wrap">
          <h2 class="invite__title d1" data-aos="animation-scale-top" data-aos-duration="600">Get started with CT Forms today</h2>
          <div class="invite__text">Create a page and start sharing the link on social media.</div><button class="invite__btn btn btn_orange">Register</button>
        </div>
        <div class="invite__gallery">
          <div class="invite__preview"><img srcset="img/figures-7.1@2x.png 2x" src="img/figures-7.1.png" alt=""/></div>
          <div class="invite__preview" data-aos="animation-translate-down" data-aos-duration="1000"><img srcset="img/figures-7.2@2x.png 2x" src="img/figures-7.2.png" alt=""/></div>
        </div>
      </div>
    </div>
  </div>);
}
import React from 'react';
import Main from '../components/Main/Main'
import Quality from '../components/Quality/Quality'
import Steps from '../components/Steps/Steps'
import Advantages from '../components/Advantages/Advantages'
import History from '../components/History/History'
import Options from '../components/Options/Options'
import Integrations from '../components/Integrations/Integrations'
import Description from '../components/Description/Description'
import Productivity from '../components/Productivity/Productivity'
import Review from '../components/Review/Review'
import Invite from '../components/Invite/Invite'

export default function Home() {
  return(
      <div>
          <Main/>
          <Quality/>
          <Steps/>
          <Advantages/>
          <History/>
          <Options/>
          <Description/>
          <Productivity/>
          <Review/>
          <Invite/>
          {/* <Integrations/> */}
      </div>
  );
}
import React, { useEffect } from "react";
import './History.css';
import '../../App.css';
import AOS from "aos";

export default function Advantages() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
    return (<div class="history">
    <div class="history__center center">
      <div class="history__container">
        <div class="history__wrap">
          <div class="stage">Find ready made templates to start</div>
          <h2 class="history__title h2" data-aos="animation-scale-top" data-aos-duration="600">Get started with form template library</h2>
          <div class="history__info">We are constantly growing our template library suitable to your needs</div><button class="history__btn btn btn_border">Start Now</button>
        </div>
      </div>
      <div class="history__bg">
        <div class="history__preview"><img srcset="img/figures-3.1@2x.png 2x" src="img/figures-3.1.png" alt=""/></div>
        <div class="history__preview" data-aos="animation-translate-down" data-aos-duration="1000"><img srcset="img/figures-3.2@2x.png 2x" src="img/figures-3.2.png" alt=""/></div>
        <div class="history__preview" data-aos="animation-translate-down" data-aos-duration="1000"><img srcset="img/figures-3.3@2x.png 2x" src="img/figures-3.3.png" alt=""/></div>
        <div class="history__preview" data-aos="animation-translate-down" data-aos-duration="1000"><img srcset="img/figures-3.4@2x.png 2x" src="img/figures-3.4.png" alt=""/></div>
      </div>
    </div>
  </div>);
}
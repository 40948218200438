import React from 'react';
import './Frame.css'

export default function Frame() {
    return (
        <div class="iphone-x">
        {/* <iframe src="https://app.forms.cyphertree.com/form-submit/17664f28-306f-4739-8777-abba01a4ab7b" 
        width="95%" 
        height="95%" 
        title="CT Forms">
        </iframe> */}
        </div>
    );
}
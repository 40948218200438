import React from 'react';
import './Header.css'
import { Link } from 'react-router-dom';

export default function Header() {
    return (
      <div className="Header">
        <header class="header">
          <div class="header__center center">
          <Link class="header__logo" to="/">
                  <img class="header__pic header__pic_dark" src='img/logo-dark.png' alt="" />
                  <img class="header__pic header__pic_light" src='img/logo-light.png' alt="" />
           </Link>
            <div class="header__wrapper">
              <nav class="header__nav">
              <Link class="header__link inactive" to="/About">About</Link>
              <Link class="header__link inactive" to="/Contact">Contact</Link>
              </nav>
            <div class="header__btns">
                <a class="header__btn btn btn_transparent js-popup-open" href="https://app.forms.cyphertree.com/login" data-effect="mfp-zoom-in">Login</a>
                <a class="header__btn btn btn_orange js-popup-open" href="https://app.forms.cyphertree.com/register" data-effect="mfp-zoom-in">Register</a>
              </div>
              <picture class="header__preview">
                <source media="(max-width: 767px)" srcset="img/header-pic-mobile@2x.png, img/header-pic-mobile@2x.png 2x" />
                <img srcset="img/header-pic@2x.png 2x" src="img/header-pic.png" alt="" />
                </picture>
            </div>
            <button class="header__burger">
            </button>
          </div>
        </header>
      </div>
    );
  }
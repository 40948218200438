import React, { useEffect } from "react";
import './Description.css';
import '../../App.css';
import AOS from "aos";

export default function Description() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
    return (<div class="description">
    <div class="description__center center">
      <div class="description__row">
        <div class="description__wrap">
          <div class="stage">hiring solution</div>
          <h2 class="description__title h2" data-aos="animation-scale-top" data-aos-duration="600">Take offline video interview</h2>
          <div class="description__text">We add more value than google forms. Create a job application form with video support. Ask coding questions using the markdown component.</div><button class="description__btn btn btn_border">Use Job Application Template</button>
        </div>
        <div class="description__bg">
          <div class="description__preview"><img srcset="img/figures-4.1@2x.png 2x" src="img/figures-4.1.png" alt=""/></div>
          <div class="description__preview" data-aos="animation-translate-down" data-aos-duration="1000"><img srcset="img/figures-4.2@2x.png 2x" src="img/figures-4.2.png" alt=""/></div>
          <div class="description__preview" data-aos="animation-translate-down" data-aos-duration="1000"><img srcset="img/figures-4.3@2x.png 2x" src="img/figures-4.3.png" alt=""/></div>
        </div>
      </div>
      <div class="description__row">
        <div class="description__wrap">
          <div class="stage">impression engagement conversion</div>
          <h2 class="description__title h2" data-aos="animation-scale-top" data-aos-duration="600">Drive more customer engagement</h2>
          <div class="description__text">Gain customer loyalty with product reviews and feedback. Retarget valued customers. Check stats of responses and impressions.</div><button class="description__btn btn btn_border">Find out more</button>
        </div>
        <div class="description__bg">
          <div class="description__preview"><img srcset="img/figures-5.1@2x.png 2x" src="img/figures-5.1.png" alt=""/></div>
          <div class="description__preview" data-aos="animation-translate-down" data-aos-duration="1000"><img srcset="img/figures-5.2@2x.png 2x" src="img/figures-5.2.png" alt=""/></div>
        </div>
      </div>
    </div>
  </div>);
}
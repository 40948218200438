import React, { useEffect } from "react";
import './Quality.css';
import '../../App.css';
import AOS from "aos";
import Frame from '../Frame/Frame'

export default function Main() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
  
    return (
        <div class="quality">
        <div class="quality__center center">
          <div class="quality__body">
            <div class="quality__bg"></div>
            <div class="quality__gallery">
            <div class="quality__preview" data-aos="animation-translate-up" data-aos-duration="1000">
            <Frame/>
            </div>
            </div>
            <div class="quality__wrap">
              <h2 align="left"class="quality__title h2" data-aos="animation-scale-top" data-aos-duration="600">Mobile friendly forms.</h2>
              <div class="quality__text">Create, design, and share forms on mobile.</div>
              <button class="quality__btn btn btn_border left">Schedule a demo</button>
            </div>
          </div>
          <div class="quality__bottom">
            <div class="quality__item">
              <div class="quality__counter">15<span class="quality__sign quality__sign_up">+</span></div>
              <div class="quality__category">Supported components</div>
            </div>
            <div class="quality__item">
              <div class="quality__counter">500<span class="quality__sign quality__sign_down"></span></div>
              <div class="quality__category">Free responses</div>
            </div>
            <div class="quality__item">
              <div class="quality__counter">5<span class="quality__sign quality__sign_down">min</span></div>
              <div class="quality__category">Video recording</div>
            </div>
          </div>
        </div>
      </div>
    );
}
import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './App.css'
import Home from './Pages/Home';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from "react-router-dom";
import {Helmet,meta} from "react-helmet";
import RoutesTracker from './RoutesTracker';
const TRACKING_ID = "G-GC485VKW0V"; // YOUR_OWN_TRACKING_ID

function App() {
  return (
    <div className="App">
       <Helmet>
          <meta charSet="utf-8" />
          <title>CT forms</title>
          <link rel="canonical" href="http://forms.cyphertree.com" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-GC485VKW0V"></script>
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'G-GC485VKW0V');
          `}</script>
        </Helmet>
        <Router>
      <RoutesTracker />
    </Router>
       
    </div>
  );
}

export default App;
